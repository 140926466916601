var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Layout",
    [
      _c("PageHeader", {
        attrs: { title: _vm.page.title, items: _vm.page.items }
      }),
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit, reset: _vm.onReset } },
        [
          _c(
            "b-card",
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.companyName
                                        ? _vm.form.companyName.length
                                        : 0,
                                      max: "100"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Company Name:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "company-name",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "company-name",
                                  placeholder: "Please enter",
                                  required: "",
                                  maxlength: "100",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.form.companyName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "companyName", $$v)
                                  },
                                  expression: "form.companyName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Company Address:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "company-address",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-select", {
                                staticClass: "col-md-3",
                                attrs: {
                                  id: "company-address",
                                  options: _vm.types.countryList,
                                  "value-field": "id",
                                  "text-field": "name",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please select');",
                                  oninput: "setCustomValidity('')"
                                },
                                on: {
                                  change: function($event) {
                                    return _vm.selectAddress(
                                      _vm.form.countryId,
                                      1
                                    )
                                  }
                                },
                                model: {
                                  value: _vm.form.countryId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "countryId", $$v)
                                  },
                                  expression: "form.countryId"
                                }
                              }),
                              _c("b-form-select", {
                                staticClass: "ml-4 col-md-3",
                                attrs: {
                                  id: "company-state",
                                  options: _vm.types.stateList,
                                  "value-field": "id",
                                  "text-field": "name",
                                  required: "",
                                  oninvalid:
                                    "setCustomValidity('Please select');",
                                  oninput: "setCustomValidity('')"
                                },
                                model: {
                                  value: _vm.form.stateId,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "stateId", $$v)
                                  },
                                  expression: "form.stateId"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.cityName
                                        ? _vm.form.cityName.length
                                        : 0,
                                      max: "50"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "City Name:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "city-name",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "city-name",
                                  placeholder: "Please enter",
                                  required: "",
                                  maxlength: "50",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.form.cityName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "cityName", $$v)
                                  },
                                  expression: "form.cityName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.zipCode
                                        ? _vm.form.zipCode.length
                                        : 0,
                                      max: "10"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Zip Code:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "zip-code",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "zip-code",
                                  placeholder: "Please enter",
                                  required: "",
                                  maxlength: "10",
                                  type: "number",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.form.zipCode,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "zipCode", $$v)
                                  },
                                  expression: "form.zipCode"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.address
                                        ? _vm.form.address.length
                                        : 0,
                                      max: "200"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Specific Address:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "company-name",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "company-name",
                                  placeholder: "Please enter",
                                  required: "",
                                  maxlength: "200",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.form.address,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "address", $$v)
                                  },
                                  expression: "form.address"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        [
                          _c(
                            "b-form-group",
                            {
                              ref: "companyBusiness",
                              attrs: {
                                label: "Company Business:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "company-business",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "d-flex flex-wrap" },
                                _vm._l(_vm.types.companyBusiness, function(
                                  label,
                                  index
                                ) {
                                  return _c(
                                    "b-form-checkbox",
                                    {
                                      key: index,
                                      staticClass: "mr-2 mt-2",
                                      attrs: {
                                        required: true,
                                        value: label.value
                                      },
                                      model: {
                                        value: _vm.form.companyBusinessId,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "companyBusinessId",
                                            $$v
                                          )
                                        },
                                        expression: "form.companyBusinessId"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(label.text) + " ")]
                                  )
                                }),
                                1
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.email
                                        ? _vm.form.email.length
                                        : 0,
                                      max: "100"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Company Email:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "email-address",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "email-address",
                                  placeholder: "Please enter",
                                  required: "",
                                  maxlength: "100",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.form.email,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "email", $$v)
                                  },
                                  expression: "form.email"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.phoneNumber
                                        ? _vm.form.phoneNumber.length
                                        : 0,
                                      max: "20"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Company phone:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "company-phone",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "company-phone",
                                  placeholder: "Please enter",
                                  required: "",
                                  maxlength: "20",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');",
                                  type: "number"
                                },
                                model: {
                                  value: _vm.form.phoneNumber,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "phoneNumber", $$v)
                                  },
                                  expression: "form.phoneNumber"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.name
                                        ? _vm.form.name.length
                                        : 0,
                                      max: "50"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Company Contacts:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "company-contacts",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "company-contacts",
                                  placeholder: "Please enter",
                                  required: "",
                                  maxlength: "50",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.form.name,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "name", $$v)
                                  },
                                  expression: "form.name"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.companyIntroductio
                                        ? _vm.form.companyIntroduction.length
                                        : 0,
                                      max: "2000"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Company Introduction:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "company-introduction",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-textarea", {
                                attrs: {
                                  id: "company-introduction",
                                  placeholder: "Please enter",
                                  rows: "10",
                                  "max-rows": "10",
                                  maxlength: "2000"
                                },
                                model: {
                                  value: _vm.form.companyIntroduction,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.form,
                                      "companyIntroduction",
                                      $$v
                                    )
                                  },
                                  expression: "form.companyIntroduction"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Status:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "status",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-radio-group", {
                                staticStyle: { "padding-top": "7px" },
                                attrs: {
                                  id: "radio-group-1",
                                  options: _vm.types.status,
                                  name: "status"
                                },
                                model: {
                                  value: _vm.form.status,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "status", $$v)
                                  },
                                  expression: "form.status"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.editType
                        ? _c(
                            "FormRow",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Brand:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "company-introduction"
                                  }
                                },
                                [
                                  _c(
                                    "b-row",
                                    {
                                      staticStyle: { "margin-left": "-10px" },
                                      attrs: { "cols-xl": "3" }
                                    },
                                    [
                                      _vm._l(_vm.brandList, function(item, i) {
                                        return _c(
                                          "b-col",
                                          { key: i, staticClass: "pd cursor" },
                                          [
                                            _c("ImageComponets", {
                                              attrs: {
                                                image: item.brandPicture
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      }),
                                      _c(
                                        "b-col",
                                        { staticClass: "pd cursor" },
                                        [
                                          _c("CreateProduct", {
                                            attrs: { name: "Create Brand" },
                                            on: {
                                              click: function($event) {
                                                return _vm.gotoAdd(_vm.item)
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    2
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "b-modal",
                        {
                          ref: "modal",
                          attrs: {
                            title: "Edit Password",
                            "hide-header": "",
                            "hide-footer": "",
                            centered: ""
                          },
                          on: { hidden: _vm.resetModal },
                          model: {
                            value: _vm.modalShow,
                            callback: function($$v) {
                              _vm.modalShow = $$v
                            },
                            expression: "modalShow"
                          }
                        },
                        [
                          _c(
                            "form",
                            {
                              ref: "form",
                              on: { submit: _vm.onSure, reset: _vm.onClose }
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Password:",
                                    "label-cols-lg": "4",
                                    "label-align-lg": "right",
                                    "label-for": "password",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "password",
                                      trim: "",
                                      required: "",
                                      placeholder: "Enter password",
                                      type: "password",
                                      state:
                                        _vm.modalData.password &&
                                        _vm.modalData.password.length >= 6 &&
                                        _vm.modalData.password.length <= 60,
                                      oninvalid:
                                        "setCustomValidity('Please enter');",
                                      oninput: "setCustomValidity('')"
                                    },
                                    model: {
                                      value: _vm.modalData.password,
                                      callback: function($$v) {
                                        _vm.$set(_vm.modalData, "password", $$v)
                                      },
                                      expression: "modalData.password"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Confirm password:",
                                    "label-cols-lg": "4",
                                    "label-align-lg": "right",
                                    "label-for": "password",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "password",
                                      trim: "",
                                      required: "",
                                      placeholder: "Enter password",
                                      type: "password",
                                      state:
                                        _vm.modalData.confirmPassword &&
                                        _vm.modalData.confirmPassword.length >=
                                          6 &&
                                        _vm.modalData.confirmPassword.length <=
                                          60 &&
                                        _vm.modalData.password ==
                                          _vm.modalData.confirmPassword,
                                      oninvalid:
                                        "setCustomValidity('Please enter');",
                                      oninput: "setCustomValidity('');"
                                    },
                                    model: {
                                      value: _vm.modalData.confirmPassword,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.modalData,
                                          "confirmPassword",
                                          $$v
                                        )
                                      },
                                      expression: "modalData.confirmPassword"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                { attrs: { "label-cols-lg": "4" } },
                                [
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "custom-width",
                                      attrs: {
                                        type: "submit",
                                        variant: "primary"
                                      }
                                    },
                                    [_vm._v("Save")]
                                  ),
                                  _c(
                                    "b-button",
                                    {
                                      staticClass: "ml-4 custom-width",
                                      attrs: { type: "reset" }
                                    },
                                    [_vm._v("Cancel ")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-card",
            [
              _c(
                "b-row",
                { staticClass: "align-items-center" },
                [
                  _c("b-col", { attrs: { cols: "12" } }, [
                    _c("div", { staticClass: "blockquote" }, [
                      _c("h4", [
                        _vm._v(
                          " Create an admin account to log in the Omni management system: "
                        )
                      ])
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "10" } },
                    [
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.username
                                        ? _vm.form.username.length
                                        : 0,
                                      max: "100"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Account Email:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "account-email",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "account-email",
                                  trim: "",
                                  required: "",
                                  maxlength: "100",
                                  placeholder: "Please enter",
                                  state:
                                    _vm.form.username &&
                                    _vm.form.username.length >= 6 &&
                                    _vm.form.username.length <= 100,
                                  disabled: _vm.editType,
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.form.username,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "username", $$v)
                                  },
                                  expression: "form.username"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.firstName
                                        ? _vm.form.firstName.length
                                        : 0,
                                      max: "60"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "First Name:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "first-name",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "first-name",
                                  trim: "",
                                  required: "",
                                  placeholder: "Please enter",
                                  maxlength: "60",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.form.firstName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "firstName", $$v)
                                  },
                                  expression: "form.firstName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u([
                            {
                              key: "right",
                              fn: function() {
                                return [
                                  _c("CountText", {
                                    attrs: {
                                      len: _vm.form.lastName
                                        ? _vm.form.lastName.length
                                        : 0,
                                      max: "60"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ])
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Last Name:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "last-name",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "last-name",
                                  trim: "",
                                  required: "",
                                  placeholder: "Please enter",
                                  maxlength: "60",
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.form.lastName,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "lastName", $$v)
                                  },
                                  expression: "form.lastName"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "FormRow",
                        {
                          scopedSlots: _vm._u(
                            [
                              !_vm.editType
                                ? {
                                    key: "right",
                                    fn: function() {
                                      return [
                                        _c("CountText", {
                                          attrs: {
                                            len: _vm.form.password
                                              ? _vm.form.password.length
                                              : 0,
                                            max: "60"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                : null
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: "Password:",
                                "label-cols-lg": "3",
                                "label-align-lg": "right",
                                "label-for": "password",
                                "label-class": "requiredRow"
                              }
                            },
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "password",
                                  trim: "",
                                  required: "",
                                  placeholder: "Please enter",
                                  type: "password",
                                  disabled: _vm.editType,
                                  maxlength: "60",
                                  state:
                                    _vm.form.password &&
                                    _vm.form.password.length >= 6 &&
                                    _vm.form.password.length <= 60,
                                  oninvalid:
                                    "setCustomValidity('Please enter');",
                                  oninput: "setCustomValidity('');"
                                },
                                model: {
                                  value: _vm.form.password,
                                  callback: function($$v) {
                                    _vm.$set(_vm.form, "password", $$v)
                                  },
                                  expression: "form.password"
                                }
                              }),
                              _vm.editType
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "outline-secondary" },
                                      on: {
                                        click: function($event) {
                                          _vm.modalShow = !_vm.modalShow
                                        }
                                      }
                                    },
                                    [_vm._v("Reset Passwords ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      !_vm.editType
                        ? _c(
                            "FormRow",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "right",
                                    fn: function() {
                                      return [
                                        _c("CountText", {
                                          attrs: {
                                            len: _vm.confirmPassword
                                              ? _vm.confirmPassword.length
                                              : 0,
                                            max: "60"
                                          }
                                        })
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                2258515422
                              )
                            },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: "Confirm Password:",
                                    "label-cols-lg": "3",
                                    "label-align-lg": "right",
                                    "label-for": "password",
                                    "label-class": "requiredRow"
                                  }
                                },
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "password",
                                      trim: "",
                                      required: "",
                                      placeholder: "Please enter",
                                      type: "password",
                                      maxlength: "60",
                                      state:
                                        _vm.confirmPassword &&
                                        _vm.confirmPassword.length >= 6 &&
                                        _vm.confirmPassword.length <= 60 &&
                                        _vm.form.password ==
                                          _vm.confirmPassword,
                                      oninvalid:
                                        "setCustomValidity('Please enter');",
                                      oninput: "setCustomValidity('');"
                                    },
                                    model: {
                                      value: _vm.confirmPassword,
                                      callback: function($$v) {
                                        _vm.confirmPassword = $$v
                                      },
                                      expression: "confirmPassword"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-form-group",
            { attrs: { "label-cols-lg": "3" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "custom-width",
                  attrs: { type: "submit", variant: "primary" }
                },
                [_vm._v("Save")]
              ),
              _c(
                "b-button",
                { staticClass: "ml-4 custom-width", attrs: { type: "reset" } },
                [_vm._v("Cancel ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/success.png"), noBtn: "OK" },
          on: { cancel: _vm.cancel, hide: _vm.hide },
          model: {
            value: _vm.successAlear,
            callback: function($$v) {
              _vm.successAlear = $$v
            },
            expression: "successAlear"
          }
        },
        [_vm._v(" Saved successfully ")]
      ),
      _c(
        "Dialog",
        {
          attrs: { imageSrc: require("@/assets/confirm.svg"), noBtn: "OK" },
          on: { cancel: _vm.cancel1, hide: _vm.hide1 },
          model: {
            value: _vm.errorAlear,
            callback: function($$v) {
              _vm.errorAlear = $$v
            },
            expression: "errorAlear"
          }
        },
        [_vm._v(" " + _vm._s(_vm.errorContent) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }